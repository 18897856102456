import Message from "@/store/messages";
import api from "@/store/api.js";
import pagination from "@/store/pagination";

const expenses = {
  namespaced: true,
  modules: {
    pg: pagination,
  },
  state: () => ({
    list: null,
    create: {
      event: [],
      external: {},
    },
    current: null,
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    current(state, value) {
      state.current = value;
    },
    list(state, value) {
      state.list = value;
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
    current(state) {
      return state.current;
    },
  },
  actions: {
    async add({ dispatch }) {
      await dispatch({ type: "create" });
      await dispatch({ type: "list" });
    },
    async update({ dispatch }) {
      await dispatch({ type: "updateReq" });
      await dispatch({ type: "list" });
    },
    create({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/v1/route/create", state.create)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "expense.created"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    updateReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/v1/route/update", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "expense.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve) => {
        commit("list", []);
        resolve();

        /*
                api.get('/v1/route/list')
                    .then((response) => {commit('list', response.data.payload), resolve()})
                    .catch((error) => {
                        reject(error)
                    })*/
      });
    },
  },
};
export default expenses;
