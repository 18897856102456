import Message from "@/store/messages";
import api from "@/store/api";
const roles = {
  namespaced: true,
  state: () => ({
    list: [
      "finance",
      "network",
      "operation",
      "education",
      "socialmedia",
      "awareness",
      "other",
    ],
    current: {
      user_id: null,
      role: null,
    },
  }),
  getters: {
    list(state) {
      return state.list;
    },
  },
  mutations: {
    role(state, value) {
      state.current.role = value;
    },
  },
  actions: {
    bulk({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/role/bulk", data.data)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "role.selection_updated"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit(
              "currentMsg",
              Message.getMessage("error", "role.not_updated"),
              {
                root: true,
              }
            );
            reject(error);
          });
      });
    },
    set({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/role", data ? data.data : state.current)
          .then((response) => {
            commit("currentMsg", Message.getMessage("success", "role.set"), {
              root: true,
            });
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (
              error.response.status == 400 &&
              error.response.data.message == "nvm required"
            ) {
              commit(
                "currentMsg",
                Message.getMessage("warn", "error.user_nvm_required"),
                { root: true }
              );
            } else if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permissions"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject();
          });
      });
    },
    demote({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/role", {
            data: data ? data.data : state.current,
          })
          .then((response) => {
            commit("currentMsg", Message.getMessage("success", "role.demote"), {
              root: true,
            });
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permissions"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject();
          });
      });
    },
  },
};
export default roles;
