import Message from "@/store/messages";
import api from "@/store/api";
const active = {
  namespaced: true,
  state: () => ({
    current: {
      user_id: null,
    },
  }),
  actions: {
    accept({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/active/confirm", state.current)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "active.accepted"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permissions"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    reject({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/active/reject", state.current)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "active.rejected"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permissions"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default active;
