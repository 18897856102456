import api from "@/store/api";
import Message from "@/store/messages";
import axios from "axios";

const cleanQuery = (obj) => {
  const query = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    } else if (obj[key] instanceof Array) {
      if (obj[key].length === 0) {
        delete obj[key];
      } else {
        //console.log(obj[key]);
        obj[key].forEach((entry) => {
          query.append(key, entry);
        });
        //query.append(key, obj[key].join(","));
      }
    } else {
      query.append(key, obj[key]);
    }
  });
  return query.toString();
};

let abortController = new AbortController();
const asyncPagination = {
  namespaced: true,
  state: () => ({
    lock: false,
    route: "",
    context: "",
    lastRequest: "",
    lastResponse: null,
    pagination: {
      isLoading: true,
      initial: true,
      currentSort: "_id",
      currentSortDir: "asc",
      pageSize: 100,
      defaultPageSize: 100,
      currentPageSize: 1,
      maxLength: 500,
      pageCount: 1,
      currentPage: 1,
      filter: "",
      filterObject: {},
      currentFilter: {},
      listLength: 1,
      listSize: 1,
      list: [],
    },
  }),
  getters: {
    context(state) {
      return state.context;
    },
    pagination(state) {
      return state.pagination;
    },
    list(state) {
      return state.pagination.list;
    },
    currentSort(state) {
      return state.pagination.currentSort;
    },
    currentSortDir(state) {
      return state.pagination.currentSortDir;
    },
    pageSize(state) {
      return state.pagination.pageSize;
    },
    currentPage(state) {
      return state.pagination.currentPage;
    },
    pageCount(state) {
      return state.pagination.pageCount;
    },
    filter(state) {
      return state.pagination.filter;
    },
    filterObject(state) {
      return state.pagination.filterObject;
    },
    listLength(state) {
      return state.pagination.listLength;
    },
    toQuery(state, getters) {
      const setting = getters.settingsQuery;
      const sort = getters.sortQuery ? "&" + getters.sortQuery : "";
      const search = getters.searchQuery ? "&" + getters.searchQuery : "";
      const filter = getters.filterQuery ? "&" + getters.filterQuery : "";
      return "?" + setting + sort + search + filter;
    },
    settingsQuery(state) {
      const settings = {
        limit:
          state.pagination.currentPageSize != 1
            ? state.pagination.currentPageSize
            : state.pagination.pageSize,
      };
      if (state.pagination.currentPage > 1) {
        settings["skip"] =
          (state.pagination.currentPageSize != 1
            ? state.pagination.currentPageSize
            : state.pagination.pageSize) *
          (state.pagination.currentPage - 1);
      }
      return cleanQuery(settings);
    },
    sortQuery(state) {
      return cleanQuery({
        sort: state.pagination.currentSort,
        sort_dir: state.pagination.currentSortDir,
      });
    },
    searchQuery(state) {
      return cleanQuery({ search: state.pagination.filter });
    },
    filterQuery(state) {
      return cleanQuery({ ...state.pagination.filterObject });
    },
  },
  mutations: {
    // We need this mutation to use the vca tables
    pagination() {
      return;
    },
    route(state, value) {
      state.route = value;
    },
    context(state, value) {
      state.context = value;
    },
    firstPage(state) {
      state.pagination.currentPage = 1;
      this.dispatch(state.context + "/request");
    },
    lastPage(state) {
      state.pagination.currentPage = state.pagination.pageCount;
      this.dispatch(state.context + "/request");
    },
    prevPage(state) {
      if (state.pagination.currentPage > 1) {
        state.pagination.currentPage--;
        this.dispatch(state.context + "/request");
      }
    },
    nextPage(state) {
      if (state.pagination.currentPage < state.pagination.pageCount) {
        state.pagination.currentPage++;
        this.dispatch(state.context + "/request");
      }
    },
    pageSize(state, value) {
      state.pagination.pageSize =
        value > state.pagination.maxLength ? state.pagination.maxLength : value;
    },
    currentPageSize(state, value) {
      state.pagination.currentPageSize =
        value > state.pagination.maxLength ? state.pagination.maxLength : value;
    },
    currentPage(state, value) {
      state.pagination.currentPage =
        value > state.pagination.pageCount ? state.pagination.pageCount : value;
    },
    listLength(state, value) {
      if (value > 0) {
        state.pagination.listLength =
          value > state.pagination.maxLength
            ? state.pagination.maxLength
            : value;
      } else {
        state.pagination.listLength = 1;
      }
    },
    pageCount(state) {
      if (state.pagination.listSize > 1) {
        if (
          state.pagination.listSize == state.pagination.pageSize &&
          state.pagination.currentPageSize == 1
        ) {
          state.pagination.pageCount = state.pagination.currentPage + 1;
        } else {
          state.pagination.pageCount = state.pagination.currentPage;
        }
      } else {
        state.pagination.pageCount = 1;
        state.pagination.currentPage = 1;
      }
    },
    handleResponse(state, response) {
      if (!response.data) {
        return;
      }
      state.lastResponse = response;
      state.pagination.list = response.data.payload;

      const list_size = response.data.list_size ? response.data.list_size : 1;
      state.pagination.listSize = list_size;

      if (response.data.payload && state.pagination.pageSize <= list_size) {
        if (state.pagination.currentPageSize != 1) {
          state.pagination.pageSize = state.pagination.currentPageSize;
          this.commit(
            state.context + "/listLength",
            state.pagination.currentPageSize
          );
          state.pagination.currentPageSize = 1;
        } else {
          this.commit(
            state.context + "/listLength",
            state.pagination.maxLength
          );
        }
      } else {
        if (state.pagination.pageSize > state.pagination.currentPageSize) {
          state.pagination.currentPageSize = state.pagination.pageSize;
        }
        state.pagination.pageSize = list_size;
        this.commit(state.context + "/listLength", list_size);
      }

      this.commit(state.context + "/pageCount");
      state.lock = false;
      state.pagination.isLoading = false;
    },
  },
  actions: {
    sort({ state, dispatch }, data) {
      if (state.pagination.currentSort == data.col) {
        state.pagination.currentSortDir =
          state.pagination.currentSortDir == "desc" ? "asc" : "desc";
      }
      state.pagination.currentSort = data.col;
      dispatch({ type: "request" });
    },
    request(
      { state, commit, getters },
      data = { initial: false, abort: false }
    ) {
      if (data.data && data.data.abort) {
        state.lock = false;
        state.pagination.isLoading = false;
        abortController.abort();
        abortController = new AbortController();
      }
      if (state.lock == false) {
        const q = getters.toQuery;
        state.pagination.isLoading = true;
        state.lock = true;

        if (state.lastRequest == q && state.lastResponse) {
          commit("handleResponse", state.lastResponse);
          return;
        }

        state.lastRequest = q;
        return new Promise((resolve, reject) => {
          api
            .get(state.route + q, {
              signal: abortController.signal,
            })
            .then((response) => {
              commit("handleResponse", response);
              abortController = new AbortController();
              resolve(response.data.payload);
            })
            .catch((error) => {
              if (!axios.isCancel(error)) {
                state.lock = false;
                state.pagination.isLoading = false;
                commit(
                  "currentMsg",
                  Message.getMessage("error", "error.unknown"),
                  { root: true }
                );
                abortController = new AbortController();
                reject(error);
              }
            });
        });
      }
    },
  },
};

export default asyncPagination;
