//import Message from '@/store/messages'
//import api from '@/store/api.js'

const settings = {
  namespaced: true,
  state: () => ({
    current: {
      event_view: "cards",
    },
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
  },
};
export default settings;
