import Message from "@/store/messages";
import api from "@/store/api.js";
import message from "./message";
import pagination from "@/store/pagination";
const mailbox = {
  namespaced: true,
  modules: {
    pg: pagination,
    inbox_pg: pagination,
    draft_pg: pagination,
    outbox_pg: pagination,
    message: message,
  },
  state: () => ({
    current: {
      id: null,
      email: null,
      type: null,
      inbox: [],
      outbox: [],
      draft: [],
    },
    id: null,
  }),
  mutations: {
    id(state, value) {
      state.id = value.mailbox_id;
      state.message.create.mailbox_id = value.mailbox_id;
      state.message.create.user_id = value.user_id;
    },
    current(state, value) {
      state.current = value;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    inbox(state) {
      return state.current.inbox;
    },
    outbox(state) {
      return state.current.outbox;
    },
    draft(state) {
      return state.current.draft;
    },
    getMailbox: (state) => (message_id) => {
      if (
        state.current.inbox != null &&
        state.current.inbox.length > 0 &&
        state.current.inbox.find((el) => el.id == message_id)
      ) {
        return "inbox";
      }
      if (
        state.current.outbox != null &&
        state.current.outbox.length > 0 &&
        state.current.outbox.find((el) => el.id == message_id)
      ) {
        return "outbox";
      }
      if (
        state.current.draft != null &&
        state.current.draft.length > 0 &&
        state.current.draft.find((el) => el.id == message_id)
      ) {
        return "draft";
      }
      return null;
    },
  },
  actions: {
    get({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("/mails/mailbox/" + state.id)
          .then((response) => {
            commit("current", response.data.payload);
            commit("message/mailbox_id", response.data.payload.id);
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default mailbox;
