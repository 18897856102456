import pagination from "@/store/pagination";
import eventhelper from "@/store/events/eventhelper.js";

const aspevents = {
  namespaced: true,
  modules: {
    pg: pagination,
  },
  state: () => ({
    list: [],
    publishedstates: ["published", "finished", "closed"],
  }),
  getters: {
    list(state) {
      return state.list;
    },
    getEvent: (state) => (id) => {
      if (state.list) {
        return state.list ? state.list.find((el) => el.id == id) : {};
      }
    },
  },
  mutations: {
    list(state, value) {
      value
        ? value.forEach((row) => {
            eventhelper.expand(row, state);
          })
        : null;
      state.list = value;
    },
  },
};
export default aspevents;
