import router from "@/router";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  timeout: 1000 * 20, // Wait for 20 seconds
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response == undefined ||
      (error.response.status !== 401 &&
        error.response.data.message !== "missing or malformed jwt")
    ) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }
    if (error.config.url == "/auth/refresh") {
      return new Promise((_, reject) => {
        reject(error);
        router.push("/logout");
      });
    }
    return new Promise((resolve, reject) =>
      api
        .get("/auth/refresh")
        .then((response) => {
          if (response.status === 200) {
            const config = error.config;
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
                router.push("/logout");
              });
          }
        })
        .catch((error) => {
          reject(error);
          router.push("/logout");
        })
    );
  }
);

export default api;
