import Message from "@/store/messages";
import api from "@/store/api.js";

const newsletter = {
  namespaced: true,
  state: () => ({
    current: [{ value: "none" }],
    current_value: [{ value: "none" }],
    list: [
      {
        title: "profile.newsletter.none.title",
        subtitle: "profile.newsletter.none.subtitle",
        label: "profile.newsletter.none.label",
        value: "none",
      },
      {
        title: "profile.newsletter.all.title",
        subtitle: "profile.newsletter.all.subtitle",
        label: "profile.newsletter.all.label",
        value: "all",
      },
      {
        title: "profile.newsletter.regional.title",
        subtitle: "profile.newsletter.regional.subtitle",
        label: "profile.newsletter.regional.label",
        value: "regional",
      },
      {
        title: "profile.newsletter.global.title",
        subtitle: "profile.newsletter.global.subtitle",
        label: "profile.newsletter.global.label",
        value: "global",
      },
    ],
    current_list: [],
    edit_list: [],
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
    reset(state) {
      state.current = state.current_value;
    },
    edit_list(state, value) {
      state.edit_list = value;
    },
    initial(state) {
      state.edit_list = state.current.map((el) => {
        return el.value;
      });
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
    current(state) {
      return state.current;
    },
    current_list(state) {
      return state.current.map((el) => {
        return el.value;
      });
    },
  },
  actions: {
    create({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/newsletter", { ...data.data })
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "newsletter.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    delete({ commit, state }, data) {
      var element = data.data.id
        ? data.data
        : state.current.find((element) => {
            return element.value === data.data.value;
          });
      return new Promise((resolve, reject) => {
        api
          .delete("/users/newsletter/" + element.id)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "newsletter.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default newsletter;
