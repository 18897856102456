import api from "@/store/api.js";

const certificate = {
  namespaced: true,
  state: () => ({
    list: null,
  }),
  mutations: {
    list(state, value) {
      state.list = value;
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
  },
  actions: {
    get({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/assets/" + data.data.filename)
          .then((response) => {
            commit("list", response);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default certificate;
