import mailbox from "./mailbox";

const emails = {
  namespaced: true,
  modules: {
    mailbox: mailbox,
  },
  state: () => ({
    receivers: [
      {
        title: "email.receivers_list.crew.title",
        subtitle: "email.receivers_list.crew.subtitle",
        label: "email.receivers_list.crew.label",
        value: "crew",
      },
      {
        title: "email.receivers_list.event.title",
        subtitle: "email.receivers_list.event.subtitle",
        label: "email.receivers_list.event.label",
        value: "event",
      },
      {
        title: "email.receivers_list.employee_crew.title",
        subtitle: "email.receivers_list.employee_crew.subtitle",
        label: "email.receivers_list.employee_crew.label",
        value: "employee_crew",
      },
    ],
    mailboxList: [],
    selectMailbox: null,
  }),
  getters: {
    receivers(state) {
      return state.receivers;
    },
    mailboxList(state) {
      return state.mailboxList;
    },
    mailboxSelect(state) {
      if (state.mailboxList.length === 1) {
        return false;
      }
      return true;
    },
  },
  mutations: {
    mailboxList(state, val) {
      state.mailboxList = val;
    },
    selectMailbox(state, val) {
      state.selectMailbox = val;
      state.mailbox.id = val[0] ? val[0].value : null;
    },
  },
  actions: {
    mailboxList({ dispatch, commit, rootState, rootGetters }) {
      var mailboxList = [];
      if (rootGetters["user/roles/hasSystemPermission"]()) {
        mailboxList.push({
          title: "email.mailbox.select.employee.title",
          subtitle:
            rootState.user.current.email + " / no-reply@vivaconagua.org",
          label: "email.mailbox.select.employee.label",
          value: rootState.user.current.mailbox_id,
          type: "employee",
          email: rootState.user.current.email,
        });
      }
      if (rootGetters["user/crew/hasCrew"]) {
        mailboxList.push({
          title: "email.mailbox.select.crew.title",
          subtitle: rootState.user.crew.current.email,
          label: "email.mailbox.select.crew.label",
          value: rootState.user.crew.current.mailbox_id,
          type: "crew",
          email: rootState.user.crew.current.email,
        });
      }
      if (mailboxList.length > 0) {
        commit("mailboxList", mailboxList);
        commit("selectMailbox", mailboxList.slice(0, 1));
        commit("mailbox/message/selectMailbox", mailboxList.slice(0, 1));
      } else {
        console.log("TODO Error");
      }
      dispatch("events/email", null, { root: true });
      dispatch("emails/mailbox/get", null, { root: true });
      dispatch("crews/own", null, { root: true });
    },
  },
};
export default emails;
