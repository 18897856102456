import Message from "@/store/messages";
import api from "@/store/api.js";

const active = {
  namespaced: true,
  state: () => ({
    current: {
      status: "",
    },
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    isActive(state) {
      if (state.current.status == "confirmed") {
        return true;
      }
      return false;
    },
    isRequested(state) {
      if (state.current.status == "requested") {
        return true;
      }
      return false;
    },
    canApply(state, getters, rootState) {
      if (
        rootState.user.crew.current.id != "" &&
        !getters.isRequested &&
        !getters.isActive
      ) {
        return true;
      }
      return false;
    },
  },
  actions: {
    requestActive({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/active/request")
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "active.requested"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    withdrawActive({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/active/withdraw")
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "active.withdrawn"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default active;
