import Message from "@/store/messages";
import api from "@/store/api.js";

const nvm = {
  namespaced: true,
  state: () => ({
    current: {
      status: "",
    },
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    isActive(state) {
      if (state.current.status == "confirmed") {
        return true;
      }
      return false;
    },
    isExpired(state) {
      if (state.current.status == "expired") {
        return true;
      }
      return false;
    },
    isWithdrawn(state) {
      if (state.current.status == "withdrawn") {
        return true;
      }
      return false;
    },
  },
  actions: {
    confirm({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/nvm/confirm")
          .then((response) => {
            commit("current", response.data.payload);
            commit("currentMsg", Message.getMessage("success", "nvm.created"), {
              root: true,
            });
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    set({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/nvm/confirm/" + data)
          .then((response) => {
            commit("current", response.data.payload);
            commit("currentMsg", Message.getMessage("success", "nvm.set"), {
              root: true,
            });
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    withdraw({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/nvm/withdraw")
          .then((response) => {
            commit("current", response.data.payload);
            commit("currentMsg", Message.getMessage("success", "nvm.deleted"), {
              root: true,
            });
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    reject({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/nvm/reject/" + data)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "nvm.rejected"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default nvm;
