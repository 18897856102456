import Message from "@/store/messages";
import api from "@/store/api.js";

const avatar = {
  namespaced: true,
  state: () => ({
    current: {
      id: null,
      file_id: null,
      url: null,
      type: "png",
    },
  }),
  mutations: {
    avatarURL(state, value) {
      state.current.file_id = value;
      state.current.url =
        process.env.VUE_APP_BACKEND_URL + "/avatars/img/" + value;
    },
  },
  actions: {
    async delete({ dispatch }) {
      await dispatch({ type: "deleteAvatar" });
      //await dispatch({ type: "deleteRequest" });
      await dispatch("refresh", null, { root: true });
    },
    async upload({ state, dispatch }, data) {
      if (!state.current.id) {
        await dispatch({ type: "uploadAvatar", data: data.data });
        //await dispatch({ type: "create" });
      } else {
        await dispatch({ type: "deleteAvatar" });
        await dispatch({ type: "uploadAvatar", data: data.data });
        //await dispatch({ type: "update" });
      }
      await dispatch("refresh", null, { root: true });
    },
    uploadAvatar({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/avatar/upload", data.data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.status == 201) {
              commit("avatarURL", response.data.payload.id);
              resolve();
            }
            resolve();
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permission"),
                { root: true }
              );
            } else if (error.response.status == 413) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.too_big"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    /*
    create({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/avatar", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "avatar.created"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/avatar", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "avatar.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    deleteRequest({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/avatar/" + state.current.id)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "avatar.deleted"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permissions"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },*/
    deleteAvatar({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/avatar/img/" + state.current.file_id)
          .then((response) => {
            //commit("current", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.permissions"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default avatar;
