import Message from "@/store/messages";

import { required } from "vuelidate/lib/validators";
import api from "@/store/api";
const address = {
  namespaced: true,
  state: () => ({
    current: {
      street: "",
      number: "",
      zip: "",
      city: "",
      country: "",
      country_code: "",
      additionals: "",
      meta: {},
    },
  }),
  mutations: {
    current(state, val) {
      state.current = val;
    },
    country(state, val) {
      state.country = val;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    country(state) {
      return state.country;
    },
    hasAddress(state) {
      return state.current.id != "";
    },
    validations() {
      return {
        current: {
          street: {
            required,
          },
          number: {
            required,
          },
          zip: {
            required,
          },
          city: {
            required,
          },
          country: {
            required,
          },
        },
      };
    },
  },
  actions: {
    create({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/address", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "address.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/address", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "address.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/address/" + data.data)
          .then((respnse) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "address.deleted"),
              { root: true }
            );
            resolve(respnse.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default address;
