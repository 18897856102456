function fromBinary(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function toBinary(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
}

function toQuery(object) {
  var obj = object;
  const query = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
    if (obj[key] instanceof Array) {
      if (obj[key].length === 0) {
        delete obj[key];
      } else {
        obj[key].forEach((entry) => {
          query.append(key, entry);
        });
      }
    } else {
      query.append(key, obj[key]);
    }
  });
  return "?" + query.toString();
}

export { fromBinary, toBinary, toQuery };
