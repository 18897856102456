const navigation = {
  namespaced: true,
  state: () => ({
    current: "Home",
    tabs: [
      { title: "navigation.tabs.overview", route: { name: "OverView" } },
      { title: "navigation.tabs.events", route: { name: "EventView" } },
    ],
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    tabs(state) {
      return state.tabs;
    },
  },
  actions: {
    current({ commit }, data) {
      commit("current", data);
    },
    getNavigation() {
      // get from backend
      // commit to state
    },
  },
};
export default navigation;
