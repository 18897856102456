import api from "@/store/api";
import Message from "@/store/messages";
const crew = {
  namespaced: true,
  state: () => ({
    create: {
      crew_id: null,
    },
    current: {
      id: "",
    },
    select: [],
  }),
  mutations: {
    select(state, val) {
      state.select = val;
      state.create.crew_id = val[0] ? val[0].value : null;
    },
    current(state, val) {
      state.current = val;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    create(state) {
      return state.create;
    },
    hasCrew(state) {
      return state.current && state.current.id != "";
    },
  },
  actions: {
    create({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/crew", state.create)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "crew.created"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("warn", "crew.not_found"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    update({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/crew", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "crew.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    delete({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/crew")
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "crew.deleted"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default crew;
