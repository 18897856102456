import Message from "@/store/messages";
import api from "@/store/api.js";
//import event_list from './eventlist.json'
import { required } from "vuelidate/lib/validators";

const tour = {
  namespaced: true,
  state: () => ({
    create: {
      name: "",
      tournee_id: "",
      artists: [],
      artist_ids: [],
      events: [],
    },
    current: null,
    list: [],
    crew_model: {
      type_of_event: "concert",
      location: {
        name: "",
        street: "",
        city: "",
        country: "",
        country_code: "",
        number: "",
        position: {
          lat: 0,
          lng: 0,
        },
        place_id: "",
        sublocality: "",
      },
      start_at: parseInt((new Date().getTime() + 6048e5 * 6) / 1000),
      end_at: parseInt((new Date().getTime() + 6048e5 * 6 + 216e5) / 1000),
      crew: {
        id: "",
        name: "",
        email: "",
      },
      event_asp: {
        user_id: "",
        full_name: "",
        display_name: "",
        email: "",
        phone: "",
      },
      application: {
        start_date: parseInt((new Date().getTime() + 6048e5 * 3) / 1000),
        end_date: parseInt((new Date().getTime() + 6048e5 * 5) / 1000),
        supporter_count: 1,
      },
      event_state: {
        state: "requested",
        crew_confirmation: "",
        internal_confirmation: "",
        taking_id: "",
        deposit_id: "",
      },
    },
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
    create(state, value) {
      state.create = value;
    },
    prepare(state, value) {
      state.create.name = value.name;
      state.create.artists = value.artists;
      state.create.artist_ids = value.artist_ids;
      state.create.creator = value.creator;

      state.create.events = state.create.events.map((el) => ({
        ...value,
        ...el,
      }));
    },
    crewsList(state, value) {
      state.crewsList = value;
    },
  },
  getters: {
    crewsList(state) {
      return state.crewsList;
    },
    validations() {
      let validation = {
        crew: {
          id: { required },
        },
      };

      return {
        create: validation,
        value: validation,
        current: validation,
      };
    },
    create(state) {
      return state.create;
    },
    current(state) {
      return state.current;
    },
    model(state) {
      return state.crew_model;
    },
  },
  actions: {
    async create({ dispatch }) {
      await dispatch({ type: "createReq" });
      await dispatch({ type: "list" });
    },
    async update({ dispatch }) {
      await dispatch({ type: "updateReq" });
      await dispatch({ type: "list" });
    },
    createReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/events/tour", state.create)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "tour.created"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    get({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/tour/" + state.current.id)
          .then((response) => {
            commit("current", response.data.payload);
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default tour;
