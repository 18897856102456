import profile from "./profile.js";
import address from "./address.js";
import newsletter from "./newsletter.js";
import crew from "./crew.js";
import active from "./active.js";
import nvm from "./nvm.js";
import roles from "./roles.js";
import settings from "./settings.js";
import participations from "../events/participations.js";
import avatar from "./avatar.js";
import aspevents from "./aspevents.js";
import emails from "@/store/emails";

const user = {
  modules: {
    profile: profile,
    address: address,
    crew: crew,
    settings: settings,
    aspevents: aspevents,
    emails: emails,
    newsletter: newsletter,
    participations: participations,
    active: active,
    nvm: nvm,
    roles: roles,
    avatar: avatar,
  },
  namespaced: true,
  state: () => ({
    loginCallback: "/",
    current: null,
    id: null,
    email: null,
    country: null,
    mailbox_id: null,
    modified: {
      updated: 0,
      created: 0,
    },
  }),
  mutations: {
    reset_message(state) {
      state.emails.mailbox.message.create = {
        from: null,
        subject: null,
        message: null,
        user_id: null,
        mailbox_id: null,
        recipient_group: {
          type: null,
          nvm_state: [],
          active_state: [],
          state: [],
          event_id: null,
          ignore_newsletter: false,
        },
      };
    },
    loginCallback(state, val) {
      state.loginCallback = val;
    },
    login(state, val) {
      state.current = val;
      state.id = val.id;
      state.email = val.email;
      state.country = val.country;
      state.roles.system = val.system_roles;
      state.roles.pool = val.pool_roles;
      state.modified = val.modified;
      state.profile.current = val.profile;
      state.address.current = val.address;
      state.crew.current = val.crew;
      if (val.crew.crew_id) {
        state.crew.select = [{ value: val.crew.crew_id }];
        state.crew.create.crew_id = val.crew.crew_id;
      }
      state.active.current = val.active;
      state.nvm.current = val.nvm;
      state.avatar.current = val.avatar;
      state.newsletter.current = val.newsletter;
    },
    logout(state) {
      state.current = null;
      state.id = null;
      state.email = null;
      state.country = null;
      state.roles.system = null;
      state.roles.pool = null;
      state.modified = null;
      state.profile.current = null;
      state.address.current = null;
      state.crew.current = { id: "" };
      state.crew.select = [];
      state.crew.create.crew_id = null;
      state.active.current = { id: "" };
      state.nvm.current = { id: "" };
      state.avatar.current = { id: "" };
    },
  },
  getters: {
    roles(state) {
      return state.roles;
    },
    current(state) {
      return state.current;
    },
    loginCallback(state) {
      return state.loginCallback;
    },
    hasBirthdate(state) {
      return state.current.profile && state.current.profile.birthdate;
    },
  },
};
export default user;
