import Message from "@/store/messages";
import api from "@/store/api.js";
import { helpers } from "vuelidate/lib/validators";

const number = helpers.regex("serial", /^\+{0,1}[0-9]{1,14}$/);
const profile = {
  namespaced: true,
  state: () => ({
    list: null,
    current: {},
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    current(state, value) {
      state.current = value;
    },
    list(state, value) {
      state.list = value;
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
    current(state) {
      return state.current;
    },
    has(state) {
      if (state.current.id == "") {
        return false;
      }
      return true;
    },
    validations() {
      return {
        current: {
          phone: {
            number,
          },
        },
      };
    },
  },
  actions: {
    create({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/profile", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "profile.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/profile", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "profile.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    uploadReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/v1/route/update", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "profile.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve) => {
        commit("list", {});
        resolve();

        /*
                api.get('/v1/route/list')
                    .then((response) => {commit('list', response.data.payload), resolve()})
                    .catch((error) => {
                        reject(error)
                    })*/
      });
    },
  },
};
export default profile;
