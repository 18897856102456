import Message from "@/store/messages";
import asyncPagination from "@/store/asyncPagination";
import api from "@/store/api";
const roles_history = {
  namespaced: true,
  modules: {
    pg: asyncPagination,
  },
  state: () => ({
    current: null,
    list: [],
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
    list(state, value) {
      state.list = value;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    list(state) {
      return state.list;
    },
  },
  actions: {
    bulk({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/role_history/bulk", data.data)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "role.selection_updated"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit(
              "currentMsg",
              Message.getMessage("error", "role.not_updated"),
              {
                root: true,
              }
            );
            reject(error);
          });
      });
    },
    confirm({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/role_history/confirm", data.data)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "role.selection_updated"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit(
              "currentMsg",
              Message.getMessage("error", "role.not_updated"),
              {
                root: true,
              }
            );
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/role_history")
          .then((response) => {
            commit("list", response.data.payload);
            commit("pg/pageSize", response.data.payload.length);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default roles_history;
