import { email, required } from "vuelidate/lib/validators";
import Message from "@/store/messages";
import api from "@/store/api.js";

const message = {
  namespaced: true,
  state: () => ({
    create_origin: {
      from: null,
      subject: null,
      message: null,
      user_id: null,
      mailbox_id: null,
      recipient_group: {
        type: null,
        nvm_state: [],
        active_state: [],
        state: [],
        event_id: null,
        ignore_newsletter: false,
      },
    },
    create: {
      from: null,
      subject: null,
      message: null,
      user_id: null,
      mailbox_id: null,
      recipient_group: {
        type: null,
        nvm_state: [],
        active_state: [],
        state: [],
        event_id: null,
        crew_id: null,
        ignore_newsletter: false,
      },
    },
    selectCrew: null,
    current: null,
    mailbox_id: null,
    user_id: null,
    selectMailbox: null,
  }),
  getters: {
    create(state) {
      return state.create;
    },
    validations() {
      let validation = {
        subject: { required },
        from: { required, email },
        recipient_group: {
          type: { required },
        },
        message: { required },
      };

      return {
        create: validation,
        current: validation,
        value: validation,
      };
    },
    mailboxSelect(state, getters, rootState) {
      if (
        state.create.recipient_group.type === "crew" ||
        state.create.recipient_group.type === "employee_crew" ||
        rootState.emails.mailboxList.length === 1
      ) {
        return false;
      }
      return true;
    },
    mailboxType(state) {
      return state.selectMailbox[0].type;
    },
    create_origin(state) {
      return state.create_origin;
    },
  },
  mutations: {
    selectMailbox(state, val) {
      state.selectMailbox = val;
      state.create.mailbox_id = val[0] ? val[0].value : null;
      state.create.from = val[0] ? val[0].email : null;
    },
    create(state, value) {
      state.create = value;
    },
    reset_create(state) {
      state.create = { ...state.create_origin };
    },
    create_event(state, value) {
      state.create.recipient_group.type = "event";
      state.create.recipient_group.event_id = value.id;
    },
    current(state, value) {
      state.current = value;
    },
    mailbox_id(state, value) {
      state.create.mailbox_id = value;
    },
    user_id(state, value) {
      state.create.user_id = value;
    },
    selectCrew(state, val) {
      state.selectCrew = val;
      state.create.recipient_group.crew_id = val[0] ? val[0].value : null;
    },
  },
  actions: {
    create({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/mails/message", state.create)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "message.created"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/mails/message", state.current)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "message.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    delete({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .delete("/mails/message/" + state.current.id)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "message.deleted"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    send({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("/mails/message/send_cycular/" + state.current.id)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "message.sent"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    get({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/mails/message/" + data.id)
          .then((response) => {
            commit("current", response.data.payload);
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default message;
