import Message from "@/store/messages";
import api from "@/store/api";

const roles = {
  namespaced: true,
  state: () => ({
    system: [],
    pool: [],
    current: {
      user_id: null,
      role: null,
    },
    poolEventPermissions: ["operation", "network", "education"],
  }),
  mutations: {
    current(state, value) {
      state.current.user_id = value.user_id;
      state.current.role = value.name;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    system(state) {
      return state.system;
    },
    pool(state) {
      return state.pool;
    },
    poolEventPermissions(state) {
      return state.poolEventPermissions;
    },
    hasPoolPermission: (state) => (permission) => {
      if (!permission) {
        return state.pool != null && state.pool.length > 0;
      } else if (!Array.isArray(permission)) {
        return (
          state.pool != null && state.pool.find((el) => el.name == permission)
        );
      } else {
        return (
          state.pool != null &&
          state.pool.find((el) => permission.includes(el.name))
        );
      }
    },
    hasSystemPermission: (state) => (permission) => {
      if (!permission) {
        return state.system != null && state.system.length > 1;
      } else if (!Array.isArray(permission)) {
        return (
          state.system != null &&
          state.system.find((el) => el.name == permission)
        );
      } else {
        return (
          state.system != null &&
          state.system.find((el) => permission.includes(el.name))
        );
      }
    },
  },
  actions: {
    set({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/role", data.data ? data.data : state.current)
          .then(() => {
            commit("currentMsg", Message.getMessage("success", "role.set"), {
              root: true,
            });
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    demote({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/role", {
            data: data && data.data ? data.data : state.current,
          })
          .then(() => {
            commit("currentMsg", Message.getMessage("success", "role.demote"), {
              root: true,
            });
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
  },
};
export default roles;
